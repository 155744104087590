/**
 * 当前系统id
 */
export const SYSTEM_ID = 4;

/**
 * 文件对象存储服务
 * 自行搭建 minio - 1 天翼云 ctyun - 2
 */
export const OSS_TYPE = +process.env.VUE_APP_OOS_TYPE;

/**
 * 一些链接配置
 */
const ALL_ENV_WEBSITE = {
  dev: {
    gyfzpt: "https://www.test.gyfzpt.com/",
    sc_gyfzpt: "https://sc.test.gyfzpt.com/",
    emap_gyfzpt: "https://e.map.test.gyfzpt.com/",
    ct_gyfzpt: "https://ct.test.gyfzpt.com/",
    ts_gyfzpt: 'https://ts.test.gyfzpt.com/',
    dr_gyfzpt: 'https://dr.test.gyfzpt.com/',
    ps_gyfzpt: 'https://ps.test.gyfzpt.com/',
  },
  test: {
    gyfzpt: "https://www.test.gyfzpt.com/",
    sc_gyfzpt: "https://sc.test.gyfzpt.com/",
    emap_gyfzpt: "https://e.map.test.gyfzpt.com/",
    ct_gyfzpt: "https://ct.test.gyfzpt.com/",
    ts_gyfzpt: 'https://ts.test.gyfzpt.com/',
    dr_gyfzpt: 'https://dr.test.gyfzpt.com/',
    ps_gyfzpt: 'https://ps.test.gyfzpt.com/',
  },
  prod: {
    gyfzpt: "https://www.gyfzpt.com/",
    sc_gyfzpt: "https://sc.gyfzpt.com/",
    emap_gyfzpt: "https://e.map.gyfzpt.com/",
    ct_gyfzpt: "https://ct.gyfzpt.com/",
    ts_gyfzpt: 'https://ts.gyfzpt.com/',
    dr_gyfzpt: 'https://dr.gyfzpt.com/',
    ps_gyfzpt: 'https://ps.gyfzpt.com/',
  },
};

export const gyWebsite = ALL_ENV_WEBSITE[process.env.VUE_APP_API_ENV];
