const keys = {
  dev: {
    accessKey: "nT5hY3gR5yS0qE5b", // java接口accessKey
    secretKey: "iK8zA3eB6aA7tH3eF5dS4oX9iD5oH8cG", // java接口secretKey
    imageSecurityKey: "vLZo4cqxmhA5klyWyXW5", // 图片服务安全key
    amapWebServiceKey: "45a04e1cebb9cba0407f7d48f52ffafc", // 高德地图web服务key
    amapWebEndKey: "34989755d5440a8f63a476c78700d2d5", // 高德地图web端key(jsapi)
    securityJsCode: "01d0116e820d27ffeea89e454fd787b8", // 高德地图jscode安全密钥
    ctyunOssAk: "0ab3d8c7a3a5e3d6a849", // 天翼云图片存储ak
    ctyunOssSk: "f2a517104ed977dd81a63de421b3d772cb54fc92", // 天翼云图片存储sk
  },
  test: {
    accessKey: "nT5hY3gR5yS0qE5b", // java接口accessKey
    secretKey: "iK8zA3eB6aA7tH3eF5dS4oX9iD5oH8cG", // java接口secretKey
    imageSecurityKey: "tqZcNGVyGdVPpQmrqzrD", // 图片服务安全key
    amapWebServiceKey: "45a04e1cebb9cba0407f7d48f52ffafc", // 高德地图web服务key
    amapWebEndKey: "34989755d5440a8f63a476c78700d2d5", // 高德地图web端key(jsapi)
    securityJsCode: "01d0116e820d27ffeea89e454fd787b8", // 高德地图jscode安全密钥
    ctyunOssAk: "2b8fa34a4192f3c2c21c", // 天翼云图片存储ak
    ctyunOssSk: "cd6dd95484f276a24f2783b9ef6261920c9675e9", // 天翼云图片存储sk
  },
  prod: {
    accessKey: "nT5hY3gR5yS0qE5b", // java接口accessKey
    secretKey: "iK8zA3eB6aA7tH3eF5dS4oX9iD5oH8cG", // java接口secretKey
    imageSecurityKey: "xjwXvByGleYjRZOuu5dO", // 图片服务安全key
    amapWebServiceKey: "45a04e1cebb9cba0407f7d48f52ffafc", // 高德地图web服务key
    amapWebEndKey: "34989755d5440a8f63a476c78700d2d5", // 高德地图web端key(jsapi)
    securityJsCode: "01d0116e820d27ffeea89e454fd787b8", // 高德地图jscode安全密钥
    ctyunOssAk: "c4c997d294b484304dad", // 天翼云图片存储ak
    ctyunOssSk: "e7f418a63ecc7cffeb4d9508f36b4ec62a6d5bc8", // 天翼云图片存储sk
  },
};

export default keys[process.env.VUE_APP_API_ENV];
