import router from "@/router";
import { ACCESS_TOKEN } from "@/config/constant";
import Cookies from "js-cookie";
import store from "@/store";

const authList = ["/edit-user-info"];
router.beforeEach((to, from, next) => {
  const token = Cookies.get(ACCESS_TOKEN);
  if (token) {
    store.dispatch("GetLocalInfo");
    next();
  } else {
    if (authList.indexOf(to.path) > -1) {
      next({ path: "/login" });
    } else {
      next();
    }
  }
});
