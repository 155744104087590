/**
 * token
 */
export const ACCESS_TOKEN = getEnvWords("Frontend-Access-Token");

/**
 * userInfo
 */
export const USER_INFO = getEnvWords("Frontend-User-Info");

/**
 * 根据不同环境得到不同的值
 */
function getEnvWords(key) {
  return process.env.VUE_APP_API_ENV === "prod"
    ? key
    : process.env.VUE_APP_API_ENV + "-" + key;
}
