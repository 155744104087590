<template>
  <header class="global-header-wrapper" :class="{ 'has-height': hasHeight }">
    <div
      class="inner"
      :class="{
        white: bgWhite || noScroll,
        'index-page': routeName === 'home',
      }"
    >
      <div class="inner-box">
        <div class="left">
          <img
            class="logo"
            :src="bgWhite || noScroll ? logoImg : logoWhiteImg"
            alt="高阳纺织产业数字化服务平台"
            @click="$router.push('/home')"
          />
          <div class="menus">
            <router-link
              class="menu-item"
              :class="{ act: routeName === 'home' }"
              to="/home"
            >
              首页
            </router-link>
            <div
              class="sub-menu"
              @mouseenter="mouseenterFn"
              @mouseleave="mouseleaveFn"
            >
              <router-link
                class="menu-item products"
                :class="{ act: routeName === 'products-and-services' }"
                to="/products-and-services"
              >
                <span class="text">产品及服务</span>
                <i class="arrow"></i>
              </router-link>
              <div class="drop-down">
                <div class="module">
                  <div class="title">产品</div>
                  <div class="link-wrapper">
                    <div class="link-box">
                      <a
                        :href="gyWebsite.sc_gyfzpt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        数字商品交易平台
                      </a>
                    </div>
                    <div class="link-box">
                      <a
                        :href="gyWebsite.emap_gyfzpt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        地方产业数字地图
                      </a>
                    </div>
                    <div class="link-box">
                      <a
                        :href="gyWebsite.ct_gyfzpt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        数字产能交易平台
                      </a>
                    </div>
                    <div class="link-box">
                      <a
                        :href="gyWebsite.ts_gyfzpt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        数字人才服务平台
                      </a>
                    </div>
                    <div class="link-box">
                      <a
                        :href="gyWebsite.dr_gyfzpt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        数字设计资源平台
                      </a>
                    </div>
                  </div>
                </div>
                <div class="module">
                  <div class="title">服务</div>
                  <div class="link-wrapper">
                    <div class="link-box">
                      <router-link
                        :class="{ act: routeName === 'public-service' }"
                        to="/public-service"
                      >
                        企业公共服务
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="sub-menu"
              @mouseenter="mouseenterFn"
              @mouseleave="mouseleaveFn"
            >
              <router-link
                class="menu-item products"
                :class="{ act: routeName === 'service-system' }"
                to="/service-system"
              >
                <span class="text">服务体系</span>
                <i class="arrow"></i>
              </router-link>
              <div class="drop-down">
                <div class="module w-100">
                  <div class="title">更多服务</div>
                  <div class="link-wrapper">
                    <div class="link-box w-16">
                      <router-link
                        :class="{ act: routeName === 'smart-manufacturing' }"
                        to="/smart-manufacturing"
                        target="_blank"
                      >
                        智能织造
                      </router-link>
                    </div>
                    <div class="link-box w-16">
                      <router-link
                        :class="{ act: routeName === 'textile-code' }"
                        to="/textile-code"
                        target="_blank"
                      >
                        纺织码
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <router-link
              class="menu-item"
              :class="{ act: routeName === 'service-system' }"
              to="/service-system"
            >
              服务体系
            </router-link> -->
            <router-link
              class="menu-item"
              :class="{ act: routeName === 'news' }"
              to="/news"
            >
              新闻资讯
            </router-link>
            <router-link
              class="menu-item"
              :class="{ act: routeName === 'about' }"
              to="/about"
            >
              关于我们
            </router-link>
          </div>
        </div>
        <div class="right">
          <template v-if="isLogined">
            <span
              class="my-center-btn"
              @click="$router.push('/edit-user-info')"
            >
              个人中心
            </span>
            <a-button
              type="text"
              class="quit-btn"
              size="small"
              @click="logoutHandle"
            >
              退出
            </a-button>
          </template>
          <template v-else>
            <button class="btn btn1" @click="$router.push('/login')">
              登录
            </button>
            <button class="btn btn2" @click="$router.push('/register')">
              注册
            </button>
          </template>
        </div>
      </div>
    </div>
  </header>
  <header class="mobile-header-wrapper">
    <div class="mobile-header-bar">
      <img
        class="logo"
        src="../../assets/logo.png"
        alt="高阳纺织产业数字化服务平台"
        @click="$router.push('/home')"
      />

      <div class="right">
        <template v-if="isLogined">
          <a-button
            type="text"
            class="btn"
            @click="$router.push('/edit-user-info')"
          >
            个人中心
          </a-button>
        </template>
        <template v-else>
          <a-button
            type="text"
            class="btn login"
            @click="$router.push('/login')"
          >
            登录
          </a-button>
          <a-button
            type="text"
            class="btn reg"
            @click="$router.push('/register')"
          >
            注册
          </a-button>
        </template>
        <div class="menu-btn" @click="menuVisible = true">
          <menu-outlined />
        </div>
      </div>
    </div>
    <div class="mobile-header-popup" v-show="menuVisible">
      <div class="mobile-header-bar">
        <img
          class="logo"
          src="../../assets/logo.png"
          alt="高阳纺织产业数字化服务平台"
          @click="$router.push('/home')"
        />

        <div class="right">
          <div class="menu-btn" @click="menuVisible = false">
            <close-outlined />
          </div>
        </div>
      </div>
      <div class="menu-wrapper">
        <div class="menu-list">
          <span
            class="menu-item"
            :class="{ act: routeName === 'home' }"
            @click="toLink('/home')"
          >
            首页
          </span>
          <div class="sub-menu">
            <span
              class="menu-item products"
              :class="{ act: routeName === 'products-and-services' }"
              @click="toLink('/products-and-services')"
            >
              <span class="text">产品及服务</span>
              <i
                class="arrow"
                :class="{ open: mobileProductsDropDown }"
                @click.stop.prevent="
                  mobileProductsDropDown = !mobileProductsDropDown
                "
              ></i>
            </span>
            <div class="drop-down" v-show="mobileProductsDropDown">
              <div class="module">
                <div class="title">产品</div>
                <div class="link-wrapper">
                  <div class="link-box">
                    <a
                      :href="gyWebsite.sc_gyfzpt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      数字商品交易平台
                    </a>
                  </div>
                  <div class="link-box">
                    <a
                      :href="gyWebsite.emap_gyfzpt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      地方产业数字地图
                    </a>
                  </div>
                  <div class="link-box">
                    <a
                      :href="gyWebsite.ct_gyfzpt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      数字产能交易平台
                    </a>
                  </div>
                  <div class="link-box">
                    <a
                      :href="gyWebsite.ts_gyfzpt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      数字人才服务平台
                    </a>
                  </div>
                  <div class="link-box">
                    <a
                      :href="gyWebsite.dr_gyfzpt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      数字设计资源平台
                    </a>
                  </div>
                </div>
              </div>
              <div class="module">
                <div class="title">服务</div>
                <div class="link-wrapper">
                  <div class="link-box">
                    <router-link
                      :class="{ act: routeName === 'public-service' }"
                      to="/public-service"
                    >
                      企业公共服务
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-menu">
            <span
              class="menu-item products"
              :class="{ act: routeName === 'service-system' }"
              @click="toLink('/service-system')"
            >
              <span class="text">服务体系</span>
              <i
                class="arrow"
                :class="{ open: mobileServicesDropDown }"
                @click.stop.prevent="
                  mobileServicesDropDown = !mobileServicesDropDown
                "
              ></i>
            </span>
            <div class="drop-down" v-show="mobileServicesDropDown">
              <div class="module">
                <div class="title">更多服务</div>
                <div class="link-wrapper">
                  <div class="link-box">
                    <router-link
                      :class="{ act: routeName === 'smart-manufacturing' }"
                      to="/smart-manufacturing"
                    >
                      智能织造
                    </router-link>
                  </div>
                  <div class="link-box">
                    <router-link
                      :class="{ act: routeName === 'textile-code' }"
                      to="/textile-code"
                    >
                      纺织码
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <span
            class="menu-item"
            :class="{ act: routeName === 'service-system' }"
            @click="toLink('/service-system')"
          >
            服务体系
          </span> -->
          <span
            class="menu-item"
            :class="{ act: routeName === 'news' }"
            @click="toLink('/news')"
          >
            新闻资讯
          </span>
          <span
            class="menu-item"
            :class="{ act: routeName === 'about' }"
            @click="toLink('/about')"
          >
            关于我们
          </span>
        </div>
        <div class="logout-box" v-if="isLogined">
          <a-button size="large" block @click="logoutHandle">退出登录</a-button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, defineProps, computed, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import logoImg from "../../assets/logo.png";
import logoWhiteImg from "../../assets/logo_white.png";
import { useStore } from "vuex";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { gyWebsite } from '@/config/defaultSettings'
const props = defineProps({
  hasHeight: {
    type: Boolean,
    default: false,
  },
  noScroll: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const router = useRouter();
const store = useStore();
console.log(store.state);
const routeName = computed(() => {
  return route.name;
});

const isLogined = computed(() => {
  return !!store.state.user?.token;
});

const bgWhite = ref(false);
let scrollTop = 0;
let mouseIsEntered = false;
function getScrollTop() {
  return (
    document.documentElement.scrollTop ||
    window.pageYOffset ||
    document.body.scrollTop
  );
}
function scroll() {
  scrollTop = getScrollTop();
  if (scrollTop >= 64) {
    bgWhite.value = true;
  } else {
    if (mouseIsEntered) {
      bgWhite.value = true;
    } else {
      bgWhite.value = false;
    }
  }
}

onMounted(() => {
  if (!props.noScroll) {
    scrollTop = getScrollTop();
    document.addEventListener("scroll", scroll);
  }
});
onUnmounted(() => {
  if (!props.noScroll) {
    document.removeEventListener("scroll", scroll);
  }
});

function mouseenterFn() {
  // console.log("mouseenterFn");
  mouseIsEntered = true;
  bgWhite.value = true;
}
function mouseleaveFn() {
  mouseIsEntered = false;
  if (scrollTop < 64) bgWhite.value = false;
  else bgWhite.value = true;
}

const menuVisible = ref(false);
const mobileProductsDropDown = ref(false);
const mobileServicesDropDown = ref(false);
function toLink(url) {
  menuVisible.value = false;
  router.push(url);
}

// 登出
function logoutHandle() {
  store
    .dispatch("Logout")
    .then(() => {
      message.success("登出成功");
      console.log("登出成功");
      menuVisible.value = false;
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>

<style lang="less" scoped>
.global-header-wrapper {
  height: 0;
  &.has-height {
    height: 64px;
  }
  .inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    &.index-page {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &::after {
      content: " ";
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 1px;
      background-color: #bfbfbf;
      transform: scaleY(0.5);
    }
    .inner-box {
      margin: 0 auto;
      max-width: 1600px;
      min-width: 1200px;
      height: 64px;

      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .left {
      display: flex;
      align-items: center;
    }
    .logo {
      width: 248px;
      height: 29px;
      object-fit: contain;
      cursor: pointer;
    }

    .menus {
      margin-left: 45px;
      height: 64px;
      display: flex;
    }
    .menu-item {
      margin-right: 24px;
      height: 64px;
      min-width: 120px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        position: absolute;
        bottom: -1px;
        left: 50%;
        content: "";
        width: 88px;
        height: 0px;
        background: linear-gradient(90deg, #0077e9 0%, #3dcff8 100%);
        border-radius: 5px;
        transition: all 0.2s;
        margin-left: -44px;
        z-index: 2;
        opacity: 0;
      }
      &.products::after {
        width: 120px;
        margin-left: -60px;
      }
      // &:hover::after,
      &.act::after {
        opacity: 1;
        height: 4px;
      }
      // &.products:hover::after,
      // &.products.act::after {
      //   // width: 120px;
      // }
      .arrow {
        margin-left: 8px;
        width: 14px;
        height: 14px;
        background-image: url(../../assets/icons/ic_down_white.png);
        background-size: cover;
      }
    }
    .sub-menu {
      min-width: 120px;
      display: flex;
      &:hover .drop-down {
        opacity: 1;
        height: auto;
        overflow: visible;
        transform: scaleY(1);
      }
    }
    .drop-down {
      position: absolute;
      top: 64px;
      left: 50%;
      width: 1200px;
      margin-left: -600px;
      background-color: #fff;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: 50% 0;
      transition: all 0.2s;
      padding: 30px 40px 74px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 0 0 1px #eee;
      .module {
        width: 505px;
        &.w-100 {
          width: 1120px;
        }
        .title {
          font-size: 18px;
          font-weight: 500;
          color: #adb9c3;
          line-height: 1;
          padding-bottom: 28px;
          position: relative;
          &::after {
            content: " ";
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            height: 1px;
            background-color: #bfbfbf;
            transform: scaleY(0.5);
          }
        }

        .link-wrapper {
          display: flex;
          flex-wrap: wrap;
        }
        .link-box {
          margin-top: 40px;
          width: 33.33%;
          &.w-16 {
            width: 16.66%;
          }
          a {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            &:hover,
            &.act {
              color: #2a60f3;
            }
          }
        }
      }
    }

    .right {
      .my-center-btn {
        cursor: pointer;
        transition: all 0.3s;
        font-size: 14px;
        color: #fff;
      }
      .quit-btn {
        color: #fff;
        margin-left: 16px;
      }
      .btn {
        margin-left: 24px;
        width: 64px;
        height: 32px;
        border-radius: 20px;
        font-size: 14px;
        appearance: none;
        border: none;
        cursor: pointer;

        &.btn1 {
          background: transparent;
          border: 1px solid #fff;
          color: #fff;
        }
        &.btn2 {
          background: #2a60f3;
          color: #fff;
        }
      }
    }
    &.index-page.white {
      background-color: #fff;
    }
    &.white {
      transition: all 0.2s;
      background-color: #fff;
      .menu-item {
        color: #333;
      }
      .menu-item.act,
      .menu-item:hover {
        color: #2a60f3;
      }
      .menu-item .arrow {
        background-image: url(../../assets/icons/ic_down_black.png);
        transition: 0.2s;
      }
      .sub-menu:hover .menu-item .arrow,
      .sub-menu:hover .menu-item.act .arrow {
        background-image: url(../../assets/icons/ic_up_blue.png);
      }

      .sub-menu .menu-item.act .arrow {
        background-image: url(../../assets/icons/ic_down_blue.png);
      }

      .my-center-btn {
        color: #333;
      }

      .quit-btn {
        color: #333;
      }

      .right .btn1 {
        border: 1px solid #2a60f3;
        color: #2a60f3;
      }
    }
  }
}

// 移动端
.mobile-header-wrapper {
  display: none;
  height: 50px;
  .mobile-header-bar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-left: 16px;
    z-index: 99;
    .logo {
      width: calc(100vw - 136px);
      max-width: 204px;
    }
    .right {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 8px;

      .btn {
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 16px;
        font-size: 15px;
      }
      .menu-btn {
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
      }
    }
  }
  .mobile-header-popup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 100;
    .menu-wrapper {
      height: 100vh;
      padding-top: 50px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .menu-list {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
    }
    .menu-item {
      height: 54px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      font-size: 16px;
      &.act {
        color: #2a60f3;
      }
    }
    .sub-menu {
      min-height: 54px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #eee;
      .menu-item {
        border-bottom: none;
      }
      .menu-item .arrow {
        margin-right: -16px;
        width: 54px;
        height: 54px;
        background: url(../../assets/icons/ic_down_black.png) no-repeat;
        background-size: 16px 16px;
        background-position: center center;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
    .drop-down {
      padding-bottom: 10px;
      .title {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        color: #999;
        font-weight: 500;
        margin: 6px 0;
      }
      .link-box {
        height: 50px;
        line-height: 50px;
        a {
          color: #333;
          font-size: 16px;
          &.act {
            color: #2a60f3;
          }
        }
      }
    }
    .logout-box {
      padding: 24px 16px;
    }
  }
}

@media screen and (max-width: 810px) {
  .global-header-wrapper {
    display: none;
  }
  .mobile-header-wrapper {
    display: block;
  }
}
</style>
