import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: '/public-service'
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/index.vue"),
    meta: { title: "首页" },
  },
  {
    path: "/public-service",
    name: "public-service",
    component: () => import("../views/public-service/index.vue"),
    meta: { title: "企业公共服务" },
  },
  {
    path: "/products-and-services",
    name: "products-and-services",
    component: () => import("../views/products-and-services/index.vue"),
    meta: { title: "产品及服务" },
  },
  {
    path: "/service-system",
    name: "service-system",
    component: () => import("../views/service-system/index.vue"),
    meta: { title: "服务体系" },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news/index.vue"),
    meta: {
      title: "新闻资讯",
    },
  },
  {
    path: "/public-service",
    name: "public-service",
    component: () => import("../views/public-service/index.vue"),
    meta: {
      title: "公共服务",
    },
  },
  {
    path: "/news-detail",
    name: "news-detail",
    component: () => import("../views/news/detail.vue"),
    meta: {
      title: "新闻详情",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about/index.vue"),
    meta: { title: "关于我们" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/user/login.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/user/register.vue"),
    meta: { title: "注册" },
  },
  {
    path: "/edit-user-info",
    name: "edit-user-info",
    component: () => import("../views/user/edit-user-info.vue"),
    meta: { title: "完善信息" },
  },
  {
    path: "/submit-success",
    name: "submit-success",
    component: () => import("../views/user/submit-success.vue"),
    meta: { title: "提交成功" },
  },
  {
    path: "/smart-manufacturing",
    name: "smart-manufacturing",
    component: () => import("../views/smart-manufacturing/index.vue"),
    meta: { title: "智能织造" },
  },
  {
    path: "/textile-code",
    name: "textile-code",
    component: () => import("../views/textile-code/index.vue"),
    meta: { title: "纺织码" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
});

export default router;
