import { codeLogin, passwordLogin, register } from "@/api/user";
import { ACCESS_TOKEN, USER_INFO } from "@/config/constant";
import Cookies from "js-cookie";
import { getTopDomain } from "@/utils/tool";
export default {
  state() {
    return {
      token: "",
      info: null,
    };
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
  },

  actions: {
    // 登录
    Login({ commit }, payload) {
      return new Promise((resovle, reject) => {
        const { params, type } = payload;
        if (type === 1) {
          codeLogin(params)
            .then((res) => {
              if (res.code === 200) {
                commit("SET_TOKEN", res.data.token);
                commit("SET_INFO", res.data.userInfo);
                Cookies.set(ACCESS_TOKEN, res.data.token, {
                  expires: 7,
                  domain: getTopDomain(),
                });
                Cookies.set(USER_INFO, JSON.stringify(res.data.userInfo), {
                  expires: 7,
                  domain: getTopDomain(),
                });
                resovle(res);
              } else {
                reject(res);
              }
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else if (type === 2) {
          passwordLogin(params)
            .then((res) => {
              if (res.code === 200) {
                commit("SET_TOKEN", res.data.token);
                commit("SET_INFO", res.data.userInfo);
                Cookies.set(ACCESS_TOKEN, res.data.token, {
                  expires: 7,
                  domain: getTopDomain(),
                });
                Cookies.set(USER_INFO, JSON.stringify(res.data.userInfo), {
                  expires: 7,
                  domain: getTopDomain(),
                });
                resovle(res);
              } else {
                reject(res);
              }
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else if (type === 3) {
          // 注册
          register(params)
            .then((res) => {
              if (res.code === 200) {
                commit("SET_TOKEN", res.data.token);
                commit("SET_INFO", res.data.userInfo);
                Cookies.set(ACCESS_TOKEN, res.data.token, {
                  expires: 7,
                  domain: getTopDomain(),
                });
                Cookies.set(USER_INFO, JSON.stringify(res.data.userInfo), {
                  expires: 7,
                  domain: getTopDomain(),
                });
                resovle(res);
              } else {
                reject(res);
              }
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        }
      });
    },

    // 获取本地信息
    GetLocalInfo({ commit, state }) {
      if (!state.token) {
        const token = Cookies.get(ACCESS_TOKEN);
        let info = Cookies.get(USER_INFO);

        if (token) {
          commit("SET_TOKEN", token);
        }

        if (info) {
          try {
            info = JSON.parse(info) || {}
          } catch (error) {
            info = {}
          }
          commit("SET_INFO", info);
        }
      }
    },
    // 用户信息
    GetUserInfo() {
      return new Promise((resovle) => {
        resovle({
          msg: "developing",
        });
      });
    },

    // 登出
    Logout({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        commit("SET_INFO", null);

        Cookies.remove(ACCESS_TOKEN, {
          domain: getTopDomain(),
        });
        Cookies.remove(USER_INFO, {
          domain: getTopDomain(),
        });
        resolve();
      });
    },
  },
};
