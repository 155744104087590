<template>
  <header class="header-simple">
    <div class="header-inner">
      <img
        class="logo"
        src="../../assets/logo.png"
        alt="高阳纺织产业数字化服务平台"
        @click="$router.push('/home')"
      />
    </div>
  </header>
</template>

<script setup></script>

<style lang="less" scoped>
.header-simple {
  min-width: 1200px;
  padding: 0 40px;
  height: 88px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 1px #efefef;
  .header-inner {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .header-inner .logo {
    height: 36px;
  }
}

@media screen and (max-width: 810px) {
  .header-simple {
    min-width: 300px;
    padding: 0 16px;
    height: 60px;
    .header-inner .logo {
      height: 28px;
    }
  }
}
</style>
