import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import useAntd from "./utils/useAntd";
import PageWrapper from "./components/PageWrapper.vue";
import PageSimpleWrapper from "./components/PageSimpleWrapper.vue";
import "./style/index.less";
import "./permission";

const app = createApp(App);

useAntd(app);
app.use(store);
app.use(router);

app.component("PageWrapper", PageWrapper);
app.component("PageSimpleWrapper", PageSimpleWrapper);

app.mount("#app");
