import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  upload,
  Tabs,
  Row,
  Col,
  Steps,
  Modal,
  Cascader,
  Pagination,
  Empty,
  Spin,
  message,
  notification,
  Carousel,
  Radio
} from "ant-design-vue";

export default function useAntd(app) {
  app.use(Button);
  app.use(Form);
  app.use(Input);
  app.use(InputNumber);
  app.use(Select);
  app.use(upload);
  app.use(Tabs);
  app.use(Row);
  app.use(Col);
  app.use(Steps);
  app.use(Modal);
  app.use(Cascader);
  app.use(Pagination);
  app.use(Empty);
  app.use(Spin);
  app.use(Carousel);
  app.use(Radio)
  app.config.globalProperties.$message = message;
  app.config.globalProperties.$notification = notification;
}
