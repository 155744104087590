/**
 * 用户登录、注册
 */
import request from "../utils/request-platform";

/**
 * 登录 - 短信验证码
 */
export function codeLogin(parameter) {
  return request({
    url: "/user/login/code",
    method: "post",
    data: parameter,
    noAuth: true,
  });
}

/**
 * 登录 - 密码
 */
export function passwordLogin(parameter) {
  return request({
    url: "/user/login/password",
    method: "post",
    data: parameter,
    noAuth: true,
  });
}

/**
 * 注册
 */
export function register(parameter) {
  return request({
    url: "/user/login/codeLoginOrRegister",
    method: "post",
    data: parameter,
    noAuth: true,
  });
}

/**
 * 用户完善信息
 */
export function updateUserInfo(parameter) {
  return request({
    url: "/user/userInfo/updateByUser",
    method: "post",
    data: parameter,
  });
}

/**
 * 发送短信
 */
export function sendVerifyCode(parameter) {
  return request({
    url: "/sms/sendVerifyCode",
    method: "post",
    data: parameter,
    noAuth: true,
  });
}

/**
 * 用户详情
 */
export function getUserInfo(parameter) {
  return request({
    url: "/user/userInfo/info",
    method: "get",
    params: parameter,
  });
}
