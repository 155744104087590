<template>
  <footer class="footer">
    <div class="footer-inner">
      <div class="left">
        <img
          class="logo"
          src="../../assets/logo_white.png"
          alt="高阳纺织产业数字化服务平台"
        />
        <div class="beian">
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            冀ICP备2023007383号-1
          </a>
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13062802000091"
            target="_blank"
            rel="noopener noreferrer"
          >
            冀公网安备 13062802000091号
          </a>
        </div>
      </div>
      <div class="right">
        <div class="text">联系我们</div>
        <div class="phone">
          <i class="ic-phone"></i>
          <a href="tel:19903329516">19903329516</a>
        </div>
      </div>
    </div>
    <div class="mobile-footer-inner">
      <img
        class="logo"
        src="../../assets/logo_white.png"
        alt="高阳纺织产业数字化服务平台"
      />
      <div class="text">联系我们</div>
      <div class="phone">
        <i class="ic-phone"></i>
        <a href="tel:19903329516">19903329516</a>
      </div>

      <div class="beian">
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          冀ICP备2023007383号-1
        </a>

        <br />
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13062802000091"
          target="_blank"
          rel="noopener noreferrer"
        >
          冀公网安备 13062802000091号
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup></script>

<style lang="less" scoped>
.footer {
  min-width: 1200px;
  background: #1a1c2b;
}

.footer-inner {
  margin: 0 auto;
  width: 1200px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left .logo {
    height: 28px;
  }

  .left .beian {
    margin-top: 10px;
  }

  .left .beian a {
    margin-right: 32px;
    font-size: 12px;
    color: #ffffff;
    line-height: 28px;
    text-decoration: none;
  }

  .right .text {
    margin-top: 4px;
    font-size: 14px;
    color: #ffffff;
    line-height: 28px;
  }

  .right .phone {
    margin-top: 4px;
    display: flex;
  }

  .right .ic-phone {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    background: url(../../assets/icons/ic_phone.png) no-repeat;
    background-size: cover;
  }

  .right .phone a {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
    text-decoration: none;
  }
}

.mobile-footer-inner {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  .logo {
    height: 30px;
    margin-bottom: 16px;
  }
  .text {
    font-size: 12px;
    color: #eee;
  }
  .phone {
    display: flex;
    align-items: center;
    .ic-phone {
      width: 16px;
      height: 16px;
      background: url(../../assets/icons/ic_phone.png) no-repeat;
      background-size: cover;
    }
    a {
      margin-left: 8px;
      font-size: 14px;
      color: #fff;
    }
  }
  .beian {
    margin-top: 16px;
    text-align: center;
    a {
      color: #fff;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 810px) {
  .footer {
    min-width: 300px;
    width: 100%;
  }
  .footer-inner {
    display: none;
  }
  .mobile-footer-inner {
    display: flex;
  }
}
</style>
